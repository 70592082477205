import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { captureException, setUser } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import {
  AuthLoginInitResponseSchema,
  AuthLoginResponseSchema,
  DisruptionTypeEnum,
  OnboardingType,
  SurveyType,
} from '@kvika/api-types';
import { ModalSize } from '@kvika/modal';
import { AxiosError, AxiosRequestHeaders } from 'axios';
import { Login } from '@kvika/slices';
import { sanitisePhoneNumber, prettifyPhoneNumber } from '@kvika/string-utils';
import { ApiError } from '@kvika/api-client';
import { MediaQuery } from '@kvika/theme';
import { useMediaQuery } from '@kvika/hooks';
import StepperNavigation from '../Navigation/StepperNavigation';
import {
  getAllOnboardingTypes,
  getErrorEvent,
  getNewOnboardingModal,
  parseQueryParamsFromRouterPath,
} from '../../../utils/Utils';
import { SegmentTrackingId, trackEvent } from '../../../utils/Analytics';
import { selectShowModal, displayModal, hideModal } from '../../../store/modal';
import { displayError, clearErrorState, updateServiceStatus, clearServiceStatus } from '../../../store/error';
import { loginSuccessful, kardioLogIn } from '../../../store/session';
import { setEntityState } from '../../../store/entity';
import { setCompanyState } from '../../../store/companies';
import { setAuthenticationToken, setExternalId } from '../../../utils/AuthenticationStorage';
import {
  ServiceStatusMode,
  ErrorType,
  ErrorCodes,
  LoginMethodsEnum,
  LoginStatusProps,
  ModalFields,
  NewOnboardingPageBodyLoginPrimary,
} from '../../../types/Types';
import { getNextPage } from '../../../utils/Navigation';
import { NavigationConstants } from '../../../constants/NavigationConstants';
import { SpinnerSection } from '../Loading/LoadingScreen';
import { setCurrentOnboardingFlow, setSelectedFlows, setSkipFlowSelection } from '../../../store/answer';
import { getKvikaApiClient, parseApiError } from '../../../utils/ApiUtils';
import NewModalSlice from '../Modal/NewModalSlice';
import { Navigation } from '../../../constants/Text';

type Props = {
  captions: NewOnboardingPageBodyLoginPrimary;
  modalFields: ModalFields[];
  hasExchangeToken: boolean;
  setPhoneNumber: (phoneNumber: string) => void;
  phoneNumber: string;
  kardio?: boolean;
};

const StyledNavigation = styled.div`
  max-width: 448px;
`;

const StyledStepperNavigation = styled(StepperNavigation)`
  justify-content: flex-start;
  margin-top: 20px;

  // override forward button styling
  button {
    margin-left: 0;
  }
`;

const LoginScreenSlice = ({
  captions,
  modalFields,
  hasExchangeToken,
  setPhoneNumber,
  phoneNumber,
  kardio = false,
}: Props) => {
  const [forwardDisabled, setForwardDisabled] = React.useState<boolean>(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState<boolean>(false);
  const [isCompany, setIsCompany] = React.useState<boolean>(false);
  const [selectedLoginMethod, setSelectedLoginMethod] = React.useState<LoginMethodsEnum>(
    LoginMethodsEnum.ELECTRONIC_ID
  );
  const [ssn, setSsn] = React.useState<string>('');
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const isLoggingInWithPhoneNumber = selectedLoginMethod === LoginMethodsEnum.ELECTRONIC_ID;
  const [resetInputValue, setResetValue] = React.useState<boolean>(false);
  const stopLoginProcess = React.useRef<boolean>(false);

  const router = useRouter();
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowModal);

  const queryParams = parseQueryParamsFromRouterPath(router.asPath);

  const onResponseHeaders = (headers: AxiosRequestHeaders) => {
    const disruptionType = headers['x-disruption-type'] as DisruptionTypeEnum;
    const disruptionMessage = headers['x-disruption-message'] as string;

    if (disruptionType === DisruptionTypeEnum.EXTERNAL) {
      const newServiceStatus: ServiceStatusMode = {
        type: disruptionType,
        message: disruptionMessage,
        header: ErrorType.ERROR_SYSTEM_DISRUPTION,
      };
      dispatch(updateServiceStatus(newServiceStatus));
    }
    if (!disruptionType) {
      dispatch(clearServiceStatus());
    }
  };

  const apiClient = getKvikaApiClient(onResponseHeaders);
  const isMobile = useMediaQuery(`(max-width: ${MediaQuery.Mobile})`);

  const trackLoginEvent = (
    phoneNumber: string,
    ssn: string,
    segmentTrackingId: SegmentTrackingId,
    externalId?: string
  ) => {
    const baseProperties = {
      phoneNumber,
      ssn,
    };

    const properties = externalId ? { ...baseProperties, externalId } : baseProperties;
    trackEvent({
      event: segmentTrackingId,
      properties,
    });
  };

  React.useEffect(() => {
    const flowParams = queryParams.flow;
    const phoneNumberParams = queryParams.phone;
    const tokenParams = queryParams.token;
    const isCompanyParams = queryParams.isCompany;
    const companySsnParams = queryParams.companySSN;

    const isCompanyParamPresent = isCompanyParams && isCompanyParams.length > 0;
    if (isCompanyParamPresent) {
      setIsCompany(isCompanyParams[0] === 'true');
    }

    if (flowParams) {
      const flowsToSelect = flowParams
        .filter((fp) => getAllOnboardingTypes().includes(fp))
        .map((fp) => fp as OnboardingType);

      dispatch(setSelectedFlows(flowsToSelect));
      dispatch(setSkipFlowSelection(true));
    }

    const phoneNumberParamPresent = phoneNumberParams && phoneNumberParams.length > 0;
    const tokenParamPresent = tokenParams && tokenParams.length > 0;

    if (phoneNumberParamPresent && tokenParamPresent && isCompanyParamPresent) {
      setPhoneNumber(phoneNumberParams[0]);
      const companySsn = companySsnParams?.[0];
      loginWithToken(tokenParams[0], isCompanyParams[0] === 'true', companySsn);
    }
    // URL is on format: phone=58812345&token=USER_TOKEN&flow=capital-markets&isCompany=false&companySSN=123456789
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithToken = async (token: string, isCompany: boolean, companySsn?: string) => {
    setIsLoadingSubmit(true);
    setAuthenticationToken(token);
    // We need to get a new client here because the token was just set
    getKvikaApiClient()
      .postExchangeToken()
      .then((response) => {
        setAuthenticationToken(response.authorizationToken);
        // Create a new client here because we just set the auth token again
        const client = getKvikaApiClient();

        client
          .getCurrentAuthSession()
          .then((response) => onLoginSuccess(response, isCompany, companySsn))
          .catch((error) => {
            onLoginError(error);
            setIsLoadingSubmit(false);
          });
      })
      .catch((error) => {
        onLoginError(error);
        setIsLoadingSubmit(false);
      });
  };

  const onNavigateForward = () => {
    if (!forwardDisabled) {
      const phoneNumberSanitised = sanitisePhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumberSanitised);

      trackEvent({
        event: SegmentTrackingId.LoginStarted,
        properties: isLoggingInWithPhoneNumber ? { identifier: phoneNumber } : { identifier: ssn },
      });
      setIsLoadingSubmit(true);
      stopLoginProcess.current = false;
      dispatch(displayModal());
      apiClient
        .postStartLogin({
          identifier: isLoggingInWithPhoneNumber ? phoneNumber : ssn,
        })
        .then((response) => {
          onStartLoginSuccess(response);
        })
        .catch((error) => {
          dispatch(hideModal());
          setIsLoadingSubmit(false);
          onLoginError(error);
        });
    }
  };

  const onStartLoginSuccess = (response: AuthLoginInitResponseSchema) => {
    // If we get a verification code in the response that means we are using the auðkenni app
    response.verificationCode && setVerificationCode(response.verificationCode);

    // Wait 6 seconds until we check the login status since it always takes a few seconds to finish signing with EID or Auðkenni app
    setTimeout(() => {
      const currentDate = new Date();
      // We create this to stop polling 120 seconds from now
      const finalPollingTime = new Date(currentDate.getTime() + 120000);
      checkLoginStatus({
        loginRequestToken: response.loginRequestToken,
        firstTime: 0,
        secondTime: 1000,
        finalPollingTime,
      });
    }, 6000);
  };

  const checkLoginStatus = ({
    loginRequestToken,
    firstTime = 0,
    secondTime = 1000,
    finalPollingTime = new Date(),
  }: LoginStatusProps) => {
    !stopLoginProcess.current &&
      loginRequestToken &&
      getKvikaApiClient(onResponseHeaders, loginRequestToken)
        .getLoginStatus(isLoggingInWithPhoneNumber ? phoneNumber : ssn)
        .then((response) => {
          onLoginSuccess(response);
        })
        .catch((loginError: ApiError) => {
          // 412 means the process is still on going so we try again
          if (loginError.response?.status === 412) {
            onLoginRetry(loginRequestToken, firstTime, secondTime, finalPollingTime);
          } else {
            onLoginError(loginError);
          }
        });
  };

  const onLoginRetry = (loginRequestToken: string, firstTime: number, secondTime: number, finalPollingTime: Date) => {
    // Add this check so that we are not checking the login status infinitaly, stop if 120 seconds have passed since the first request
    if (finalPollingTime > new Date()) {
      const timeToWait = firstTime + secondTime;
      setTimeout(() => {
        // We do this to poll the api incrementally using fibonacci
        checkLoginStatus({
          loginRequestToken,
          firstTime: secondTime,
          secondTime: timeToWait,
          finalPollingTime,
        });
      }, timeToWait);
    } else {
      dispatch(hideModal());
      setIsLoadingSubmit(false);
    }
  };
  const onLoginSuccess = (response: AuthLoginResponseSchema, isCompanyPrefilled?: boolean, companySsn?: string) => {
    trackLoginEvent(phoneNumber, ssn, SegmentTrackingId.LoginCompleted, response.externalId);
    setUser({ id: response.externalId });

    setExternalId(response.externalId);
    const data = {
      ssn: response.ssn,
      name: response.fullName,
      address: response.address,
      email: response.email ?? '',
      phoneNumber,
      isCompany: isCompanyPrefilled !== undefined ? isCompanyPrefilled : isCompany,
    };
    !stopLoginProcess.current && dispatch(loginSuccessful(data));
    !stopLoginProcess.current && kardio && dispatch(kardioLogIn());

    if (!isCompany && !isCompanyPrefilled) {
      dispatch(setEntityState(data));
    }
    dispatch(hideModal());
    !stopLoginProcess.current && redirectOnLogin(isCompanyPrefilled, companySsn);
  };

  const routeForCompanies = async (companySsn?: string) => {
    // Create a new apiClient in case the auth token had just been set
    const apiClient = getKvikaApiClient();
    await apiClient
      .getCompanies()
      .then(async (response) => {
        if (response.length > 0) {
          await apiClient.getCompaniesAnswerInfo(SurveyType.Aml);
        }
        dispatch(setCompanyState({ companies: response }));
        if (response.length === 1) {
          const company = response[0];

          dispatch(
            setEntityState({
              isCompany: true,
              ssn: company.ssn,
              name: company.name,
              address: company.address,
            })
          );
        } else if (companySsn) {
          const company = response.find((company) => company.ssn === companySsn);
          if (company) {
            dispatch(
              setEntityState({
                isCompany: true,
                ssn: company.ssn,
                name: company.name,
                address: company.address,
              })
            );
          }
        }
        const nextPage = getNextPage({
          currentPage: NavigationConstants.INNSKRANING,
          props: {
            isCompany: true,
            numberOfCompanies: companySsn ? 1 : response.length, // If we have a companySsn, we know there is only one company to consider
          },
        });
        if (nextPage) {
          router.push(nextPage);
        }
      })
      .catch((error: AxiosError) => {
        dispatch(
          displayError({
            errorStatus: error.response?.status,
          })
        );
      });
  };

  const redirectOnLogin = (isCompanyPrefilled?: boolean, companySsn?: string) => {
    if (kardio) {
      dispatch(setSelectedFlows([OnboardingType.KARDIO]));
      dispatch(setCurrentOnboardingFlow(OnboardingType.KARDIO));
    }
    dispatch(clearErrorState());
    if (isCompany || isCompanyPrefilled) {
      routeForCompanies(companySsn);
    } else {
      const nextPage = getNextPage({
        onboardingFlow: kardio ? OnboardingType.KARDIO : undefined,
        currentPage: kardio ? NavigationConstants.KARDIO : NavigationConstants.INNSKRANING,
        props: {},
      });
      if (nextPage !== null) {
        router.push(nextPage);
      }
    }
  };

  const onLoginError = (error: ApiError) => {
    const disruptionType = error.response?.headers['x-disruption-type'] as DisruptionTypeEnum;
    if (disruptionType === DisruptionTypeEnum.MAINTENANCE) {
      const disruptionMessage = error.response?.headers['x-disruption-message'] as string;
      const modalHeaderKey = ErrorType.ERROR_SYSTEM_UPDATE;
      dispatch(
        displayError({
          showErrorModal: true,
          modalCustomErrorBodyText: disruptionMessage,
          modalErrorHeaderKey: modalHeaderKey,
        })
      );
    } else {
      const code = error?.response?.data.code;
      const isUserTooYoung = code === ErrorCodes.UserIsTooYoung;

      if (isUserTooYoung) {
        router.push(NavigationConstants.UNDIR_18);
      } else {
        const hasNoElectronicId = code === ErrorCodes.PhoneNumberHasNoElectronicId;

        const errorKey = hasNoElectronicId
          ? ErrorType.ERROR_NUMBER_DOES_NOT_HAVE_EID
          : ErrorType.ERROR_ELECTRONIC_ID_FAILED;

        trackLoginEvent(phoneNumber, ssn, SegmentTrackingId.LoginFailed);
        // TODO: Migrate to new Sentry implementation https://github.com/kvika/onboarding-web/issues/731
        captureException(new Error(errorKey), getErrorEvent(parseApiError(error)));
        dispatch(displayError({ showErrorModal: true, modalErrorBodyKey: errorKey }));
      }
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !forwardDisabled) {
      onNavigateForward();
    }
  };

  const getTabs = () => {
    if (kardio) return [];
    return process.env.NEXT_PUBLIC_HIDE_LEGAL_ENTITIES
      ? [captions?.tab_individual ?? '']
      : [captions?.tab_individual ?? '', captions?.tab_legal_entity ?? ''];
  };

  const getLoginMethods = () => [captions?.login_with_electronic_id ?? '', captions?.login_with_audkenni_app ?? ''];

  return (
    <>
      {showModal && (
        <NewModalSlice
          closeOnClickOutside={false}
          showCloseButton
          autoFocus={false}
          size={ModalSize.SMALL}
          modal={getNewOnboardingModal(
            modalFields ?? [],
            isLoggingInWithPhoneNumber ? 'LOGIN_PHONE_EID' : 'LOGIN_ESIM',
            {
              phoneNumber: prettifyPhoneNumber(phoneNumber),
              verificationCode,
            }
          )}
          onModalClose={() => {
            setResetValue(true);
            setIsLoadingSubmit(false);
            dispatch(hideModal());
            stopLoginProcess.current = true;
            setForwardDisabled(true);
          }}
        />
      )}
      {hasExchangeToken && <SpinnerSection />}
      {!hasExchangeToken && (
        <Login
          translations={{
            loginTitle: captions?.login_title ?? '',
            loginSubtitle: captions?.login_subtitle ?? '',
            label: isLoggingInWithPhoneNumber
              ? (captions?.phone_number_label as string)
              : (captions?.ssn_label as string),
            invalidPhoneNumberMsg: captions?.invalid_phone_number_message ?? '',
            tabs: getTabs(),
            loginMethods: getLoginMethods(),
            invalidSsnMsg: captions?.invalid_ssn_message ?? '',
          }}
          resetValues={resetInputValue}
          onValidationChange={(isValid) => setForwardDisabled(!isValid)}
          onChange={(phoneNumber, ssn, selectedLoginMethod: LoginMethodsEnum, selectedTab) => {
            setPhoneNumber(phoneNumber);
            setSsn(ssn);
            setIsCompany(selectedTab === 1);
            setSelectedLoginMethod(selectedLoginMethod);
            setResetValue(false);
          }}
          onKeyPress={onKeyPress}
          width="450px"
          defaultSelectedTab={0}
          defaultSelectedLoginMethod={0}
        />
      )}
      {!hasExchangeToken && (
        <StyledNavigation>
          <StyledStepperNavigation
            onNavigateForward={onNavigateForward}
            navigation={{
              forward_button_title: Navigation.Login,
            }}
            forwardDisabled={forwardDisabled}
            isLoadingSubmit={isLoadingSubmit}
            forwardButtonWidth={isMobile ? '100%' : '40%'}
          />
        </StyledNavigation>
      )}
    </>
  );
};

export default LoginScreenSlice;
