import * as React from 'react';
import Button, { ButtonBackgroundType } from '@kvika/button';
import { ArrowLeft, Home } from '@kvika/icons';
import { Colors } from '@kvika/theme';
import { NavigationLinkType } from '../../../types/Types';
import { StyledButton, StyledHome, StyledLink, StyledTextContainer } from './NavigationLinkStyles';

type Props = {
  link_type: string;
  link_text: string | React.ReactNode[];
  onPress?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  route?: string;
};

const NavigationLink = ({ link_type, link_text, onPress, isLoading, disabled, width, route }: Props) => {
  switch (link_type) {
    case NavigationLinkType.BackButton: {
      return (
        <Button onClick={onPress} backgroundType={ButtonBackgroundType.Text}>
          <ArrowLeft color={Colors.gold400} />
          <StyledTextContainer> {link_text} </StyledTextContainer>
        </Button>
      );
    }
    case NavigationLinkType.Button: {
      return (
        <StyledButton width={width} onClick={onPress} loading={isLoading} disabled={disabled}>
          {link_text}
        </StyledButton>
      );
    }
    case NavigationLinkType.HomeGold: {
      return (
        <div>
          <Home color={Colors.gold600} />
          <StyledLink href="wwww.kvika.is">www.kvika.is</StyledLink>
        </div>
      );
    }
    case NavigationLinkType.HomeDarkGrey: {
      return (
        <div>
          <Home color={Colors.gray900} />
          <StyledHome rel="noreferrer" href={route ?? ''}>
            {link_text}
          </StyledHome>
        </div>
      );
    }
    default:
      return null;
  }
};

export default NavigationLink;
