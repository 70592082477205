import Button from '@kvika/button';
import { Colors, Grid } from '@kvika/theme';
import styled from 'styled-components';

export const StyledHome = styled.a`
  margin-left: 12px;
  color: ${Colors.gray900};
`;

export const StyledTextContainer = styled.div`
  margin-left: ${Grid['8px']};
`;

export const StyledLink = styled.a`
  margin-left: 12px;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;
