import * as React from 'react';
import Head from 'next/head';
import { isStaging } from '../utils/Utils';

type Props = {
  url: string;
  type: string;
};

const HeadPreloadLink = ({ url, type }: Props): JSX.Element => {
  return <link rel="preload" href={url} as={type} crossOrigin="" />;
};

const IndexHead = (): JSX.Element => {
  return (
    <Head>
      {isStaging() && <meta name="robots" content="noindex" />}
      <title>Kvika</title>
      <link rel="icon" href="/favicon.ico" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Bold.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-BoldItalic.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Italic.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Regular.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Semibold.otf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Bold.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Italic.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Regular.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Thin.ttf" type="font" />
    </Head>
  );
};

export default IndexHead;
