import * as React from 'react';
import NavigationLink from './NavigationLink';
import { NavigationLinkType, NavigationTitleType } from '../../../types/Types';
import { StyledNavigation } from './StepperNavigationStyles';

type Props = {
  navigation: NavigationTitleType;
  onNavigateForward?: () => void;
  onNavigateBackwards?: () => void;
  forwardDisabled?: boolean;
  isLoadingSubmit?: boolean;
  forwardButtonWidth?: string;
  backButtonLinkType?: NavigationLinkType;
  columnDirection?: boolean;
  className?: string;
};

const StepperNavigation = ({
  navigation,
  onNavigateForward,
  onNavigateBackwards,
  forwardDisabled,
  isLoadingSubmit,
  forwardButtonWidth,
  backButtonLinkType = NavigationLinkType.BackButton,
  columnDirection = false,
  className,
}: Props) => {
  const { forward_button_title, back_button_title, route } = navigation;

  return (
    <StyledNavigation
      forwardButton={forward_button_title !== null}
      backButton={back_button_title !== null}
      columnDirection={columnDirection}
      className={className}
    >
      {back_button_title && (
        <NavigationLink
          link_type={backButtonLinkType}
          link_text={back_button_title}
          onPress={onNavigateBackwards}
          route={route ?? ''}
        />
      )}
      {forward_button_title && (
        <NavigationLink
          link_text={forward_button_title}
          link_type={NavigationLinkType.Button}
          onPress={onNavigateForward}
          isLoading={isLoadingSubmit}
          disabled={forwardDisabled}
          width={forwardButtonWidth}
        />
      )}
    </StyledNavigation>
  );
};

export default StepperNavigation;
