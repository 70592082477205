import Header from '@kvika/header';
import Layout, { ChildrenWrapper } from '@kvika/layout';
import React from 'react';
import styled from 'styled-components';
import { BarSpinner } from '@kvika/spinner';
import LayoutImage from '../../layout/LayoutImage';

type Props = {
  imageUrl?: string;
  imageAlt?: string;
};

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const LoadingScreen = ({ imageUrl = '/mynd_vindmyllur.png', imageAlt = '' }: Props) => {
  return (
    <Layout image={<LayoutImage url={imageUrl} quality={10} alt={imageAlt} />}>
      <Header />
      <ChildrenWrapper>
        <SpinnerSection />
      </ChildrenWrapper>
    </Layout>
  );
};

export const SpinnerSection = () => {
  return (
    <StyledWrapper>
      <BarSpinner />
    </StyledWrapper>
  );
};

export default LoadingScreen;
